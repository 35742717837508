@import "@amzn/meridian-tokens/base/color";

.logo {
  margin-top: 0px;
  width: 120px;
  margin-bottom: 10px;
}

.user-section {
  color: white;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.user-profile-icon {
  margin-left: 0.5rem;
  border-radius: 50%;
  height: 35px;
}

.header {
  background-color: #232f3e;
  flex-wrap: wrap;
  display: flex;
  gap: 1%;
  padding: 0.5% 2%;
}

.header-user {
  gap: 1%;
}

.header-logo {
  border-radius: 50%;
  height: 38px;
}
