.homepage-card {
  background: linear-gradient(135deg, #3ebeed, #92e3db);
  height: 200px;
  animation: gradient 15s ease infinite;
}

.homepage-card-action {
  box-shadow: 0 0px 13px rgba(0, 0, 0, 0.1);
  height: 65px;
}

.wiki-icon {
  color: #00688d;
  width: 16px;
  height: 16px;
}

.homepage-partners {
  align-items: center;
  justify-content: center;
  display: grid;
}

.header-gradient-color {
  background: linear-gradient(-45deg, #00796b, #4db6ac, #26c6da, #80deea);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.homepage-container {
  max-width: min(160vh, 1200px);
  margin-left: auto;
  margin-right: auto;
}

.homepage-description {
  box-shadow: 0 0px 13px rgba(0, 0, 0, 0.1);
  background-color: #fbfbfb;
  border-radius: 16px;
  padding: 16px;
  word-wrap: break-word;
  word-break: true;
  align-items: center;
  justify-content: center;
  width: 200;
}

.card-styles {
  width: 300px;
  height: 100%;
}

.card-content-styles {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.homepage-card {
  background: linear-gradient(135deg, #3ebeed, #92e3db);
  height: 200px;
  animation: gradient 15s ease infinite;
}

.card-heading {
  padding-bottom: 9px;
}
.clickable-wrapper {
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #e0e0e0;
    transform: translateY(0);
  }
}

.card-text-color {
  color: #428faa;
}

.integrated-services {
  width: 100%;
  padding: 25px;
}

.card-styles {
  width: 300px;
  height: 100%;
}

.askgenie-footer {
  background-color: #f8f9fa;
  display: flex;
  gap: 1%;
  padding: 0.5% 2%;
  flex-wrap: wrap;
  margin-top: 48px;
  font-size: 12px;
}
