.chatbot-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: #fff;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 80px;
  margin-right: 80px;
  box-shadow: 0 0px 13px rgba(0, 0, 0, 0.1);
}

.chatbot-box {
  flex: 1;
  padding: 20px;
  overflow-x: auto;
  overflow-y: scroll;
}

.chatbot-messages {
  margin: 6px 6px;
  padding: 5px;
  border-radius: 4px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}

.user-message {
  background-color: #77bcd7;
  color: #fff;
  text-align: left;
  padding-right: 18px;
  border-radius: 12px;
  font-family: Amazon Ember, Amazon Ember Arabic, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  white-space: pre-wrap;
  padding: 16px;
}

.bot-message {
  background-color: #f0f0f0;
  text-align: left;
  border-radius: 12px;
  font-family: Amazon Ember, Amazon Ember Arabic, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 16px;
}
.message-icon-bot {
  margin-left: 0.5rem;
  border-radius: 50%;
  height: 60px;
}
.message-icon-human {
  margin-left: 0.5rem;
  border-radius: 50%;
  height: 50px;
}

.markdown-summary {
  font-family: Amazon Ember;
  font-size: 1rem;
  padding-left: 1px;
}
.askgenie-input-form {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.scalable-textarea {
  flex: 1;
  resize: none;
  // border: 1px solid #ccc;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  width: 800px;
  max-height: 300px;
  overflow-y: auto;
  font-family: Amazon Ember, Amazon Ember Arabic, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.scalable-textarea:disabled {
  background-color: #e9ebef;
  color: #433f3f;
}

.askgenie-form {
  width: 100%;
}

// .homepage-center {
//   height: 30vh;
// }

// .homepage-btn {
//   height: 64px;
// }
