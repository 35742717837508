.footer {
  // background-color: #232f3e;
  // display: flex;
  // gap: 1%;
  // padding: 0.5% 2%;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  flex-shrink: 0;
}
// .askgenie-footer {
//   background-color: #f8f9fa;
//   display: flex;
//   gap: 1%;
//   padding: 0.5% 2%;
//   flex-wrap: wrap;
//   margin-top: 48px;
//   font-size: 12px;
// }

.logo-image {
  align-self: flex-start;
  margin-left: 0;
}

.links-icon {
  margin: 5px;
}

.links {
  color: white;
}

.logo {
  margin-top: 0px;
  width: 120px;
  margin-bottom: 10px;
}

.footer-logo {
  border-radius: 50%;
  height: 30px;
}
